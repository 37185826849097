<template>
  <div class="input-group mb-5 input-group-lg col-12 col-md-8 mx-auto">
    <input
      v-model="coupon_code"
      type="text"
      class="form-control"
      :placeholder="$t('input') + $t('coupon.number')"
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    >
    <div class="input-group-append">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="coupon_code == ''"
        @click="exchangeCoupon"
      >
        {{ $t('coupon.exchange') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExchangeCouponField',

  components: {},

  data() {
    return {
      coupon_code: '',
    };
  },

  created() {},

  methods: {
    exchangeCoupon() {},
  },
};
</script>

<style lang="scss">
@import '@/styles/vips/coupon';
</style>
