<template>
  <div>
    <div class="reg-clsapc coupon-view">
      <exchange-coupon-field />

      <div class="wuwow-card">
        <div class="wuwow-card-head">
          <h4 class="wuwow-card-title">{{ $t('coupon.exchanged_coupon_list_title') }}</h4>
        </div>

        <div class="wuwow-card-body">
          <b-card no-body class="list-card overflow-hidden">
            <b-row no-gutters>
              <b-col md="5" class="d-flex align-items-center">
                <b-card-img
                  src="https://cdn.wushare.tw/images/posts/2021/06/03112004/wuwow-one-on-one-topic-intermediate-business-main.jpg"
                  alt="Image"
                  class="rounded-0"
                />
              </b-col>
              <b-col md="7">
                <b-card-body
                  class="list-card-body d-flex flex-column justify-content-between"
                >
                  <div class="list-card-content">
                    <span class="list-card-title">商務英文溝通力 – 書信電話篇 – Intermediate Business English</span>
                    <table class="table table-bordered align-self-center">
                      <thead>
                        <tr>
                          <th>{{ $t('coupon.one_on_one') }}</th>
                          <th>{{ $t('coupon.one_to_three') }}</th>
                          <th>{{ $t('class.academy_talks') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>30{{ $t('class.class') }}</td>
                          <td>0{{ $t('class.class') }}</td>
                          <td>0{{ $t('class.class') }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="content-text">
                      {{ $t('use') }}{{ $t('end_date') }}：&nbsp; 2021/06/10 ~ ∞
                    </div>
                    <router-link
                      :to="{ name: 'couponClass', params: { id: 25 } }"
                      class="align-self-end"
                    >
                      <b-button class="btn content-btn" variant="primary">{{
                        $t('coupon.view_exhange_items')
                      }}</b-button>
                    </router-link>
                  </div>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="wuwow-card-footer">
          <b-pagination
            align="center"
            :total-rows="20"
            :per-page="5"
            @input="changePage()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import exchangeCouponField from '@/components/vip/coupon/exchangeCouponField';
export default {
  name: 'CouponPage',

  components: {
    exchangeCouponField,
  },

  data() {
    return {};
  },

  created() {},

  methods: {
    changePage() {},
  },
};
</script>

<style lang="scss">
@import '@/styles/vips/coupon';
</style>
