var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group mb-5 input-group-lg col-12 col-md-8 mx-auto"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.coupon_code,
      expression: "coupon_code"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('input') + _vm.$t('coupon.number'),
      "aria-label": "Recipient's username",
      "aria-describedby": "basic-addon2"
    },
    domProps: {
      "value": _vm.coupon_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.coupon_code = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "disabled": _vm.coupon_code == ''
    },
    on: {
      "click": _vm.exchangeCoupon
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('coupon.exchange')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }