var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "reg-clsapc coupon-view"
  }, [_c('exchange-coupon-field'), _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('coupon.exchanged_coupon_list_title')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('b-card', {
    staticClass: "list-card overflow-hidden",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "md": "5"
    }
  }, [_c('b-card-img', {
    staticClass: "rounded-0",
    attrs: {
      "src": "https://cdn.wushare.tw/images/posts/2021/06/03112004/wuwow-one-on-one-topic-intermediate-business-main.jpg",
      "alt": "Image"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-card-body', {
    staticClass: "list-card-body d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "list-card-content"
  }, [_c('span', {
    staticClass: "list-card-title"
  }, [_vm._v("商務英文溝通力 – 書信電話篇 – Intermediate Business English")]), _c('table', {
    staticClass: "table table-bordered align-self-center"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('coupon.one_on_one')))]), _c('th', [_vm._v(_vm._s(_vm.$t('coupon.one_to_three')))]), _c('th', [_vm._v(_vm._s(_vm.$t('class.academy_talks')))])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("30" + _vm._s(_vm.$t('class.class')))]), _c('td', [_vm._v("0" + _vm._s(_vm.$t('class.class')))]), _c('td', [_vm._v("0" + _vm._s(_vm.$t('class.class')))])])])]), _c('div', {
    staticClass: "content-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('use')) + _vm._s(_vm.$t('end_date')) + "：  2021/06/10 ~ ∞ ")]), _c('router-link', {
    staticClass: "align-self-end",
    attrs: {
      "to": {
        name: 'couponClass',
        params: {
          id: 25
        }
      }
    }
  }, [_c('b-button', {
    staticClass: "btn content-btn",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t('coupon.view_exhange_items')))])], 1)], 1)])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "wuwow-card-footer"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": 20,
      "per-page": 5
    },
    on: {
      "input": function ($event) {
        return _vm.changePage();
      }
    }
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }